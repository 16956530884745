<template>
  <div>
    <!-- This is where the chart will be rendered -->
    <div v-if="tempGraph" id="chartContainer"></div>
    <div v-else id="chartContainerHumid"></div>
  </div>
</template>

<script>
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import VueFusionCharts from "vue-fusioncharts";
import Vue from "vue";
import zoomline from "fusioncharts/fusioncharts.zoomline";
import moment from "moment";

// Register FusionCharts components with Vue
FusionCharts.options.creditLabel = false; // Optionally, to remove the FusionCharts watermark
Vue.use(VueFusionCharts, FusionCharts, Charts, FusionTheme, zoomline);
FusionCharts.options.license({
  key: process.env.VUE_APP_FUSION_LICENSE_KEY,
  creditLabel: false,
});

export default {
  name: "CombinedChart",
  props: {
    stats: Array,
    tempGraph: Boolean,
    title: {
      default: "Chart",
      type: String,
    },
  },
  data() {
    return {
      dataSourceTemp: null,
      dataSourceHumi: null,
    };
  },
  watch: {
    stats: "initialiseChart",
    tempGraph: "renderChart",
  },
  mounted() {
    // Initial chart rendering
    this.initialiseChart();
  },
  methods: {
    initialiseChart() {
      // Your data preparation logic goes here
      this.labels = [];
      this.temp = [];
      this.minTemp = [];
      this.maxTemp = [];
      this.humi = [];
      this.minHumi = [];
      this.maxHumi = [];
      if (this.stats) {
        for (const element of this.stats) {
          if (element.temperature === "" || element.humidity === 0) {
            continue;
          }
          let date = element.createdAt;
          if (typeof date === "string") {
            date = Number(date);
          }
          date = moment(date).format("DD/MM/YY  HH:mm");
          this.labels.push(`${date}|`);
          this.temp.push(element.temperature);
          this.maxTemp.push(element.maxTemp);
          this.minTemp.push(element.minTemp);
          this.humi.push(element.humidity);
          this.maxHumi.push(element.maxHumidity);
          this.minHumi.push(element.minHumidity);
        }
      }
      if (this.labels.length > 0) {
        this.dataSourceTemp = {
          // FusionCharts configuration for temperature chart

          chart: {
            caption: "Temperature Chart",
            subcaption: "Click & drag on the plot area to zoom & then scroll",
            yaxisname: "Temperature",
            numbersuffix: "°C",
            xaxisname: "time",
            paletteColors: "#F3C963,#28A84A,#DC3030",
            exportEnabled: "1",
            lineThickness: "1",
            compactdatamode: "1",
            // dataseparator: "|",
            labelHeight: "30",
            theme: "fusion",
            exportFileName: this.title,
          },
          categories: [
            {
              category: this.labels.join(""),
            },
          ],
          dataset: [
            {
              seriesname: "Minimum Temperature",
              data: this.minTemp.join("|"),
            },
            {
              seriesname: "Ambient Temperature",
              data: this.temp.join("|"),
            },
            {
              seriesname: "Maximum Temperature",
              data: this.maxTemp.join("|"),
            },
          ],
        };
        this.dataSourceHumi = {
          // FusionCharts configuration for humidity chart
          chart: {
            caption: "Humidity Chart",
            subcaption: "Click & drag on the plot area to zoom & then scroll",
            yaxisname: "Humidity",
            numbersuffix: "%",
            xaxisname: "time",
            paletteColors: "#F3C963,#28A84A,#DC3030",
            exportEnabled: "1",
            lineThickness: "1",
            compactdatamode: "1",
            labelHeight: "30",
            theme: "fusion",
            exportFileName: this.title,
          },
          categories: [
            {
              category: this.labels.join(""),
            },
          ],
          dataset: [
            {
              seriesname: "Minimum Humidity",
              data: this.minHumi.join("|"),
            },
            {
              seriesname: "Ambient Humidity",
              data: this.humi.join("|"),
            },
            {
              seriesname: "Maximum Humidity",
              data: this.maxHumi.join("|"),
            },
          ],
        };
      } else {
        this.dataSourceTemp = this.dataSourceHumi = null;
      }

      // Initial chart rendering based on tempGraph value
      this.renderChart();
    },
    renderChart() {
      if (this.tempGraph) {
        // Render temperature chart
        const chart = new FusionCharts({
          type: "zoomline",
          width: "90%",
          height: "400",
          renderAt: "chartContainer",
          dataFormat: "json",
          dataSource: this.dataSourceTemp,
        });
        this.$nextTick(() => {
          chart.render();
        });
      } else {
        // Render humidity chart
        const chartHumi = new FusionCharts({
          type: "zoomline",
          width: "90%",
          height: "400",
          renderAt: "chartContainerHumid",
          dataFormat: "json",
          dataSource: this.dataSourceHumi,
        });
        this.$nextTick(() => {
          chartHumi.render();
        });
      }
    },
  },
};
</script>
